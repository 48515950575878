import React, { useEffect, useRef, useState } from "react";
import "../css/components/stars.css";

const Stars = () => {   
    const containerRef = useRef(null);
    const [particles, setParticles] = useState([]);
  
    useEffect(() => {
      const container = containerRef.current; 

        function createParticle() {
          const size = Math.random() * 5;
          const particle = document.createElement("div");
          particle.classList.add("particle");
          particle.style.right = `${Math.random() * container.clientWidth}px`;
          particle.style.animationDuration = `${Math.random() * 3 + 3}s`;
          particle.style.animationDelay = `${Math.random() * 1.5}s`;
          particle.style.width = `${size}px`;
          particle.style.height = `${size}px`; //             
          return particle;
        }
  
      function addParticles() {
        for (let i = 0; i < 25; i++) {
          const particle = createParticle();
          container.appendChild(particle);
        }
      }
  
      addParticles();
    }, []);
  
    return <div className="snowfall" ref={containerRef} />;
};

export default Stars;