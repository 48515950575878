export function panelMenuClick() { 
    const panelSection = document.getElementsByClassName("media-panel")[0]
    const panelSection2 = document.getElementsByClassName("media-panel-section")[0]

    if (panelSection.style.maxHeight == "200px") {
        panelSection.style.maxHeight = 0 + "px";
        setTimeout(() => {
            panelSection2.style.display = "none";
        }, 500)
    } else {
        panelSection2.style.display = "flex";
        setTimeout(() => {
            panelSection.style.maxHeight = 200 + "px";
        }, 50)
    }
}