export function startTypingAnimation() {  
    function getRndInteger(min, max) { return Math.floor(Math.random() * (max - min) ) + min };

    const element = document.getElementById("main-title-typing");
    const text = ["безопасный", "удобный", "функциональный"];

    let position = 0;
    let textPosition = 0;
    let increment = true;
    let tempText = "";

    (function runInterval() {
        let interval = getRndInteger(200, 250);
        if (tempText.length == text[textPosition].length) {
            interval = 1250;
        };
        setTimeout(() => {
            if (position == 0 && !increment) {
                increment = true;
            if (textPosition + 1 == text.length) {
                textPosition = 0;
            } else {
                textPosition += 1;
            }
                
            } else if (tempText.length == text[textPosition].length) {
                increment = false;
            }
            if (increment) {
                tempText += text[textPosition][position];
                position += 1;
            } else {
                tempText = tempText.substring(0, tempText.length - 1);
                position -= 1;
            }
            if (!tempText) {
                element.innerHTML = "ㅤ";
            } else {
                element.innerHTML = tempText;
            }
            runInterval();
        }, interval);
    })()
}