import logo from "../favicons/logo.png";
import { Icon } from "@iconify/react/dist/iconify.js";

import redirect from "../scripts/redirect";

import "../css/components/footer.css";
import "../css/components/media/footer-media.css";

import "../css/global.css";
import toggleTooltip from "../scripts/tooltips";


function Footer({color}) {
    return (
        <footer style={{backgroundColor: color || "#323232"}}>
            <div className="footer-logo">
                <img src={logo} alt="logo"/>
            </div>
            <div className="footer-bars-container">
                <h3>Основное</h3>
                <h4 onClick={() => redirect("/")}>Главная</h4>
                <h4 onClick={() => redirect("https://github.com/TysikGG/flowjs-web/commits/main/")}>Новости</h4>
                <h4 onClick={() => redirect("/about")}>О проекте</h4>
            </div>
            <div className="footer-bars-container">
                <h3>Политика и хранение данных</h3>
                <h4 onClick={() => redirect("/safety")}>Безопасность</h4>
                <h4 onClick={() => redirect("/terms_of_use")}>Условия пользования</h4>
                <h4 onClick={() => redirect("/privacy")}>Политика конфиденциальности</h4>
            </div>
            <div className="footer-bars-container">
                <h3>Контактная информация</h3>
                <h4 onClick={() => alert("Наша почта: flowjs.mail@gmail.com")}>Контакты</h4>
                <h4 onClick={() => redirect("https://github.com/TysikGG/flowjs-web/issues/new")}>Предложить изменение</h4>
                <h4 onClick={() => redirect("https://github.com/TysikGG/flowjs-web/issues/new")}>Сообщить о проблеме</h4>
            </div>
            <div className="footer-media">
                <div className="footer-media-icons-container" onClick={() => redirect("https://github.com/TysikGG/flowjs-web")}>
                    <Icon icon="ri:github-line" className="media-icon"/>
                </div>
                <div className="footer-media-icons-container">
                    <Icon icon="ic:baseline-discord" className="media-icon"/>
                </div>
                <div className="footer-media-icons-container">
                    <Icon icon="mingcute:telegram-line" className="media-icon"/>
                </div>
                <div className="footer-media-icons-container">
                    <Icon icon="akar-icons:vk-fill" className="media-icon"/>
                </div>
            </div>
        </footer>
    );
};

export default Footer;