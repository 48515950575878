import "./css/pages/main/main-dark.css";
import "./css/global.css";
import "./css/pages/main/main-dark-media.css";

import React, { useEffect } from "react";
import { Icon } from '@iconify/react';

import Panel from "./components/panel.jsx";
import Footer from "./components/footer.jsx";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { startTypingAnimation } from "./scripts/typing.js";

import rocketImage from "./favicons/rocket-animated.gif";
import sattelite from "./favicons/sattelite.png";

import loadPlanet from "./components/planet.jsx";
import Cookies from "universal-cookie";
import redirect from "./scripts/redirect.js";
import Stars from "./components/stars.jsx";
const cookies = new Cookies()

let loadedPlanet = false;

function Page() {
    useEffect(() => {
        AOS.init({});
        startTypingAnimation();
        if (!loadedPlanet) {
            loadPlanet();
            loadedPlanet = true;
        }

    }, [])
    return (
        <div className="container">
            <Panel/>
            <main>
                <Stars/>
                    <div className="main-text-container">
                        <div className="main-inline">
                            <h1>Самый</h1>
                            <h1 id="main-title-typing" className="typing-text"></h1>
                            <h1>мессенджер</h1>
                        </div>

                        <h2 style={{ marginTop: 35 }}><b>FlowJS</b> - не просто место, где можно пообщаться.</h2>
                        <h2>Это масштабный проект на базе ИИ с множеством разных функций...</h2>
                        <h2>...и с открытым исходным кодом, что делает его лучшим выбором.</h2>
                    </div>
                    <div className="main-buttons">
                        <button>Получить приложение</button>
                        <button onClick={() => {
                            if (cookies.get("token")) {
                                redirect("/messenger")
                            } else {
                                redirect("/auth")
                            }
                        }}>Открыть в браузере</button>
                    </div>
                    <div style={{
                        width: "100%",
                        minHeight: 300,
                        zIndex: 1                        
                    }}>
                    <svg style={{
                        width: "100%",
                        marginTop: 57,
                        bottom: 0, left: 0, display: "inline-block",
                        minWidth: 1090
                    }}  className="wave" preserveAspectRatio="xMidYMax slice" 
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="rgb(20, 20, 20)" fill-opacity="1" d="M0,192L48,170.7C96,149,192,107,288,96C384,85,480,107,576,101.3C672,96,768,64,864,48C960,32,1056,32,1152,53.3C1248,75,1344,117,1392,138.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    </div>
            </main>
                <section>
                    <div className="description-block" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                        <div className="description-block-text">
                            <h1>Доступный</h1>
                            <h3 className="second-description" style={{ fontSize: 21, fontWeight: 200 }}>
                                <br/>
                                <b>FlowJS</b> доступен для каждого.<br/>
                                Во всех странах. Бесплатно.
                            </h3>
                        </div>
                        <div src="" alt="" id="world" className="no-select world"></div>
                    </div>
                </section>

                <section className="section-media">
                    <div className="description-block reversed-row" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                        <div className="description-block-text">
                            <h1>Быстрый</h1>
                            <h3 className="second-description" style={{ fontSize: 21, fontWeight: 200 }}>
                                <br/>
                                Вам не придется долго ждать. <br/>
                                Быстрая загрузка мессенджера <br/>
                                и моментальная отправка сообщений
                            </h3>
                        </div>
                        <img src={rocketImage} alt="" className="image-description-block no-select rocket"></img>
                    </div>
                </section>         

            <section className="last-block">
                <div className="last-image">
                </div>
                <div className="last-container">
                    <h1>Представьте себе...</h1>
                    <h3>Место, где можно общаться, не боясь утечки личных данных...</h3>
                    <h3>...или же мессенджер с огромным количеством функций.</h3> <br/>
                    <button onClick={() => {
                        if (cookies.get("token")) {
                            redirect("/messenger")
                        } else {
                            redirect("/auth")
                        }
                    }}>Присоединяйтесь!</button>
                </div>
                <div className="last-image">
                    <img src={sattelite} alt="sattelite" className="sattelite"></img>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Page;