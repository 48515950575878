import * as THREE from 'three';

import ThreeGlobe from 'three-globe';
import { TrackballControls } from 'three/addons/controls/TrackballControls.js';


function loadPlanet() {
    window.THREE = THREE;
    const N = 20;
    const rotateAxis = new THREE.Vector3(0, 1, 0);
    const rotateAngle = -0.005;

    const arcsData = [...Array(N).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 180,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 180,
      endLng: (Math.random() - 0.5) * 360,
      color: ['white', "gray", "black"][Math.round(Math.random() * 2)]
    }));

    const random_mesh = Math.floor(Math.random() * (1)); // 0 - 0 -- disabled
    const meshes = ["https://i.ibb.co/NjfkPSY/earth-dark.jpg"];

    const Globe = new ThreeGlobe()
      .globeImageUrl(meshes[random_mesh])
      .arcsData(arcsData)
      .arcColor('color')
      .arcDashLength(0.4)
      .arcDashGap(4)
      .arcDashInitialGap(() => Math.random() * 5)
      .arcDashAnimateTime(1000);

    const renderer = new THREE.WebGLRenderer();
    renderer.setClearColor(0x000000, 0);
    
    if (window.innerWidth > 500) {
      renderer.setSize(window.innerWidth/2, window.innerHeight/2);
    } else {
      renderer.setSize(window.innerWidth/1.1, window.innerHeight/1/1);
    }
    document.getElementById('world').appendChild(renderer.domElement);

    const scene = new THREE.Scene();
    scene.add(Globe);
    scene.add(new THREE.AmbientLight(0xcccccc, Math.PI * 2));
    scene.add(new THREE.DirectionalLight(0xffffff, 0.8 * Math.PI));

    const camera = new THREE.PerspectiveCamera();
    camera.aspect = window.innerWidth/window.innerHeight;
    camera.updateProjectionMatrix();
    if (window.innerWidth > 500) {
      camera.position.z = 290;
    } else {
      camera.position.z = 700;

    }
    if (window.innerWidth > 500) { 
      const tbControls = new TrackballControls(camera, renderer.domElement);
      tbControls.minDistance = 101;
      tbControls.rotateSpeed = 5;
      tbControls.zoomSpeed = 0; 
      tbControls.noZoom = true;
      tbControls.noPan = true;
      tbControls.maxDistance = tbControls.minDistance;  
      (function animate() {
        // Анимация
        tbControls.update();
        renderer.render(scene, camera);
        Globe.rotateOnAxis(rotateAxis, rotateAngle);
          requestAnimationFrame(animate);
      })();
    } else {
      (function animate() {
        // Анимация без контроллеров
        renderer.render(scene, camera);
        Globe.rotateOnAxis(rotateAxis, rotateAngle);
          requestAnimationFrame(animate);
      })();
    }


};

export default loadPlanet;