import * as React from "react";
import Page from "./resources/Main-dark.jsx";

// document.addEventListener("click", (e) => { включить на продакшне
//   e.target.style.display = "none"
// })

function App() {
  return <Page/>
};

export default App; 