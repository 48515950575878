import "../css/components/panel.css";
import "../css/global.css";
import "../css/components/media/panel-media.css";

import { Icon } from '@iconify/react';
import logo from "../favicons/logo.png";

import redirect from "../scripts/redirect";

import Cookies from "universal-cookie";

import { panelMenuClick } from "../scripts/panel-menu";

const cookies = new Cookies();

function Panel({type}) {
    let styleColor;
    let headerClass;
    if (type == "light") {
        styleColor = "media-panel-buttons-light";
        headerClass = "media-light"
    } else {
        headerClass = "media-dark"
    }
    return (
        <header className={headerClass}>
            <div className="panel-logo">
                <img src={logo} alt="panel-logo" width="39" height="39" onClick={() => redirect("/")}/>
            </div>
            
            <div className="panel-buttons">
                <Icon icon="ic:round-menu" className="panel-menu" onClick={() => {
                    panelMenuClick()
                }}/>
                <div className="media-hide">
                    <button onClick={() => redirect("/about")} className="button-media-hide">О проекте</button>
                    <button onClick={() => redirect("/news")} className="button-media-hide">Новости</button>
                    <button onClick={() => redirect("https://github.com/TysikGG/flowjs-web")} className="button-media-hide">GitHub</button>
                    <button onClick={() => redirect("/download")} className="button-media-hide">Скачать</button>
                </div>

            </div>
            <div className="panel-last-section panel-buttons">
                <Icon icon="ph:sun-bold" className="pointer" width="39" height="39" onClick={() => {
                    if (cookies.get("theme") == "light") {
                        cookies.set("theme", "dark")
                    } else {
                        cookies.set("theme", "light")
                    }
                    window.location.reload()
                }}/>
                <button className="get-app" onClick={() => redirect("/download")}>Скачать приложение</button>
            </div>
            <div className="media-panel-section">
                <div className="media-panel" id="media-open-animation">
                    <div className={"media-panel-buttons " + styleColor}>
                        <button onClick={() => redirect("/about")}>О проекте</button>
                        <button onClick={() => redirect("/news")}>Новости</button>
                        <button onClick={() => redirect("https://github.com/TysikGG/flowjs-web")}>GitHub</button>
                        <button className="media-button-last" onClick={() => redirect("/download")}>Скачать</button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Panel;